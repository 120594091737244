import './index.css'
import bannerRodape from '../../../assets/img2.jpg'

export default function BannerRodape(){
    return(
        <section className='containerImgRodape'>
            <div className='boxRodape'>

                <div className='boximgRodape'>
                    <img src={bannerRodape} width={500} alt='Banner2'/>
                </div>

                <div className='txtRodape'>
                    <span>
                        Controle os pedidos do seu restaurante, bar ou pizzaria de forma fácil e simples.
                    </span>   
                </div>
            </div>
        </section>
    )
}
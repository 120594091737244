import { useState, useEffect, useContext, ChangeEvent } from 'react'
import './index.css'
import Header from "../../components/Header"
import Footer from '../../components/Footer'
import { Link, useNavigate } from 'react-router-dom'
import { MdOutlineAddCircle } from 'react-icons/md'
import { FaTrash, FaEdit, FaRegSadTear, FaCheck  } from 'react-icons/fa'
import { IoCloseSharp } from "react-icons/io5"
import imgItem from '../../assets/semfoto.png'
import Loading from '../../components/Loading'
import { setupApiClient } from '../../services/api'
import Modal from '../../components/Modal'
import { AuthContext } from '../../contexts/AuthContext'
import { toast } from 'react-toastify'
import { ItemProps } from '../../components/Modal'

export interface ProdutosProps{
    CODPRODUTO: number
    NOMEPRODUTO: string
    PRECO:  string
    DESCRICAO: string
    BANNER?: string | null;
    CODCATEGORIA: number
    CATEGORIA: string
    URL?: string | null
    VISUALIZARCARDAPIO?: string | null
}


export default function Cardapio(){
    const { user } = useContext(AuthContext)
    const navigate = useNavigate()

    const [loading, setLoading] = useState(true)
    const [listaProdutos, setListaProdutos] = useState<ProdutosProps[]>([])
    const [openModal, setOpenModal] = useState(false)
    const [itemDelete, setItemDelete] = useState<ItemProps>()
    const [pesquisa, setPesquisa] = useState('')

    useEffect( () => {

        async function getProdutos(){
            try{
                setLoading(true)
                const apiClient = setupApiClient()
                const response = await apiClient.get('/produtos',{
                    params:{
                        empresa: sessionStorage.getItem('@id_meu_bar'),
                        cardapio: 'N'
                    }
                })

                setListaProdutos(response.data)
                setLoading(false)
            } catch(e){
                console.log(e)
                setListaProdutos([])
                setLoading(false)
            }
        }

        getProdutos()
    },[])

    function PesquisaProduto(e: ChangeEvent<HTMLInputElement>){
        let filtrar: string, texto: string
        const produtos = document.getElementsByClassName('div-produtos-cad')

        filtrar = e.target.value.toUpperCase()
        setPesquisa(filtrar)

        for(let i=0; i < produtos.length; i++){
            texto = produtos[i].children[1].innerHTML

            if(texto.toUpperCase().indexOf(filtrar) > -1){
                produtos[i].classList.remove('ocultarLinha')
            } else {
                produtos[i].classList.add('ocultarLinha')  
            }
        }
    }
    
    async function handleEdit(item: ProdutosProps) {
        navigate(`/produtos/new/${item?.CODPRODUTO}`)
    }

    function handleDelete(item: ProdutosProps) {
        setItemDelete({
            ID: String(item?.CODPRODUTO),
            CATEGORIA: item?.NOMEPRODUTO
        })

        setOpenModal(true)
    }

    async function handleReqDelete(id: number){
        try{
            const apiClient = setupApiClient()

            const response = await apiClient.delete('/produto',{
                params:{
                    id_produto: id,
                    empresa: sessionStorage.getItem('@id_meu_bar')
                }
            })

            if(response.data?.COD == 200){
                const respProduto = listaProdutos.filter(item => item.CODPRODUTO !== id)
                setListaProdutos(respProduto)
                toast.success('Produto deletado!')
                setOpenModal(false)
            } else {
                setLoading(false)
                toast.error(response.data?.COD+' - '+response.data?.MSG)
                return  
            }

        } catch {
            toast.error('Erro deletando o produto!')
            setOpenModal(false)
        }
    }

    if(loading){
        return(
            <main>
                <Header
                    menu={3}
                />
                <Loading/>
                <Footer/>
            </main>
        )
    }    

    return(
        <main onContextMenu={(e) => e.preventDefault()}>
            <Header
                menu={3}
            />        
            <section className='containerProduto'>
                <div className='div-border'>
                    <div className='menuCad'>
                        <h2>Meus produtos cadastrados</h2>
                    
                        <Link to='/produtos/new'>
                            <MdOutlineAddCircle size={20} color="#FFF"/>
                            Novo produto
                        </Link>
                    </div>
                    <div className='div-pesquisa'>
                        <label htmlFor="input-pesqProd">PESQUISAR</label>
                        <input 
                            id='input-pesqProd' 
                            name='input-pesqProd' 
                            type="search" 
                            placeholder='PESQUISAR...'
                            value={pesquisa}
                            onChange={(e) => PesquisaProduto(e)}
                        />
                    </div>
                </div>

                <div className='table'>
                    { listaProdutos.length <= 0 ? (
                        <div className='div-empty'>
                            <FaRegSadTear size={150} color='#ddd'/>
                            <span>Não encontrei nenhum produto...</span>    
                        </div>
                    ) : (
                    <table>
                        <thead>
                            <tr className='header'>
                                <th style={ {width:'20px'} } scope='col' title='MOSTRAR NO CARDÁPIO'>CA</th>
                                <th style={ { width:'75px', height:'30px' } } scope="col">FOTO</th>
                                <th style={ { width:'300px' } } scope="col">DESCRIÇÃO</th>
                                <th style={ { width:'150px' } } scope="col">CATEGORIA</th>
                                <th style={ { width:'100px' } } scope="col">PREÇO</th>
                                <th style={ { width:'50px' } } scope="col">AÇÕES</th>                                    
                            </tr>
                        </thead>
                        <tbody>
                        { listaProdutos.length > 0 && listaProdutos.map( (item, index) => {
                            return(
                            <tr key={index} className='div-produtos-cad'>
                                <td data-label="cardapio" className='foto-prod'> 
                                    {item?.VISUALIZARCARDAPIO == 'S' ? <FaCheck color='#2ecc71' size={25} /> : <IoCloseSharp color='red' size={40}/>} 
                                </td>
                                <td data-label="Foto" className='foto-prod'>
                                    <img className='imgProduto' src={item?.URL ? item?.URL : imgItem} alt="Foto" />
                                </td>
                                <td data-label="Descricao">{item?.NOMEPRODUTO}</td>
                                <td data-label="Categoria">{item?.CATEGORIA}</td>
                                <td data-label="Preco" style={ {textAlign:'right'} } >R$ {parseFloat(item?.PRECO).toFixed(2)}</td>
                                <td data-lable="Acoes">
                                <div className='btnAcao'>
                                    <button
                                        onClick={ () => handleEdit(item) }
                                        title='EDITAR'
                                    >
                                        <FaEdit size={20} color='#FFF'/>
                                    </button>
                                    <button 
                                        onClick={ () => handleDelete(item) }
                                        title='EXCLUIR'
                                    >
                                        <FaTrash size={20} color='#FFF'/>
                                    </button>
                                </div>
                                </td>
                            </tr>
                            )
                            })}                        
                        </tbody>
                    </table>
                )}
            </div>                

            { openModal && (
                <Modal
                    titulo='Excluir produto?'
                    titulobtn='Excluir'
                    descricao={itemDelete}
                    onRequest={ (id) => handleReqDelete(parseInt(id))}
                    onClose={ () => setOpenModal(false)}
                />
            )}
            <Footer/>
            </section>
        </main>
    )
}
import { useState } from 'react'
import './index.css'
import HeaderHome from '../../components/Home/Header'
import BannerTopo from '../../components/Home/BannerTopo'
import Funcionalidades from '../../components/Home/Funcionalidaes'
import Planos from '../../components/Home/Planos'
import BannerRodape from '../../components/Home/BannerRodape'
import Rodape from '../../components/Home/Rodape'

import ModalMenu from '../../components/Home/ModalMenu'

export default function Home(){
    const [openMenu, setOpenMenu] = useState(false)
    
    return(
        <main className='containerHome' onContextMenu={(e) => e.preventDefault()}>
            <HeaderHome
                onOpenMenu={ () => setOpenMenu(!openMenu)}
                onMenu={openMenu}
            />

            { openMenu && (
                <ModalMenu/>
            )}

            <BannerTopo/>
            <Funcionalidades/>
            <Planos/>
            <BannerRodape/>
            <Rodape/>
        </main>
    )
}
import './index.css'
import { MeuspedidosProps } from "../../pages/CardapioDurva"
import { IoCloseCircle } from "react-icons/io5"
import { useState } from 'react'

interface modalPedidoProps{
    data: MeuspedidosProps[]
    total: number
    onClose: () => void
}

export default function ModalMeusPedidos({ data, total, onClose }: modalPedidoProps){
    const [prep, setPrep] = useState('')
    
    return(
        <div className='containerModal'>
            <div className='containerCart' onClick={onClose}>
                <div className='container-item'>
                    <div className='div-menu'>
                        <span>Meus pedidos</span>
                    </div>
                    { data.map( (item, index) => {
                        if(prep != 'S'){
                            item?.CONCLUIDO == 'X' && setPrep('S')
                        }
                        return(
                            <div key={index} className='div-itens-cart'>
                                <div className='div-nomeitem'>
                                    <span>{item.NOMEPRODUTO}</span>
                                </div>
                                <div className='div-valores'>
                                    <span>{item.QUANTIDADE}</span>
                                    <span>X</span>
                                    <span>R$ {item.VALOR_UNITARIO.toFixed(2)}</span>
                                    <span>=</span>
                                    <span>R$ {item.TOTAL.toFixed(2)}</span>                                        
                                </div>
                            </div>
                        )
                    })}
                </div> 
                <div className='div-total-ped'>
                    <button onClick={onClose}>
                        <IoCloseCircle size={30} color='#C8011E' />
                    </button>
                    { prep == 'S' && (
                        <i>Existe pedido em preparação</i>
                    )}
                    <span>TOTAL R$ {total.toFixed(2)}</span>    
                </div>      
            </div> 
        </div>
    )
}
import { useEffect, useState, useContext, ChangeEvent } from 'react'
import './index.css'
import Header from "../../components/Header"
import Footer from '../../components/Footer'
import { Input } from "../../components/Input"
import { Button } from "../../components/Button"
import { BsCheckLg } from 'react-icons/bs'
import { FaTrash, FaEdit } from 'react-icons/fa'
import { MdCancel } from 'react-icons/md'
import Loading from '../../components/Loading'
import { setupApiClient } from '../../services/api'
import { toast } from 'react-toastify'
import { AuthContext } from '../../contexts/AuthContext'
import Modal from '../../components/Modal'

export interface CategoriaProps{
    ID: string;
    CATEGORIA: string;
}

export default function Categoria(){
    const { user } = useContext(AuthContext)

    const [loading, setLoading] = useState(true)
    const [nomeCategoria,setNomeCategoria] = useState('')
    const [categorias, setCategorias] = useState<CategoriaProps[]>([])
    const [categoriaUpdate, setCategoriaUpdate] = useState(null)
    const [isUpdate, setIsUpdate] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [itemDelete, setItemDelete] = useState<CategoriaProps>()
    const [pesquisa, setPesquisa] = useState('')

    useEffect( () => {
        async function GetCategorias(){
            try{
                const apiClient = setupApiClient()
                const response = await apiClient.get('/categorias',{
                    params:{
                        empresa: sessionStorage.getItem('@id_meu_bar')
                    }
                })

                setCategorias(response.data)
                setLoading(false)
            } catch{
                setCategorias([])
                setLoading(false)
            }
        }

        GetCategorias()
    },[])

    async function handleSalvar(){
        if (nomeCategoria === ''){
            toast.warn('Digite nome da categoria');
            return;
        }

        if (!isUpdate){
            const categoryExists = categorias.find(category => {
                return category.CATEGORIA === nomeCategoria.toUpperCase()
            }) 
    
            if (categoryExists){
                toast.warn('Essa categoria já existe! :/')
                return;
            }
    
            try{
                setLoading(true)
                const apiClient = setupApiClient()
    
                const resp = await apiClient.post('/categoria',{
                    categoria: nomeCategoria.toUpperCase(),
                    empresa: user?.id 
                })

                if(resp.data?.COD == 201){
                    const response = await apiClient.get('/categorias',{
                        params:{
                            empresa: user?.id
                        }
                    })

                    setCategorias([])
                    setCategorias(response.data)
                    setLoading(false)
                    toast.success('Categoria cadastrada! :)')
                } else {
                    setLoading(false)
                    toast.error(resp.data?.COD+' - '+resp.data?.MSG)
                    return 
                }
            } catch(error) {
                console.log(error)
                setLoading(false)
                toast.error('Erro cadastrando categoria!')
                return;
            }

        } else {
            try{
                setLoading(true)
                const apiClient = setupApiClient()
    
                const resp = await apiClient.put('/categoria',{
                    categoria: nomeCategoria.toUpperCase(),
                    id: categoriaUpdate?.ID,
                    empresa: user?.id  
                })
    
                if(resp.data?.COD == 200){
                    const response = await apiClient.get('/categorias',{
                        params:{
                            empresa: user?.id
                        }
                    })

                    setCategorias([])
                    setCategorias(response.data)
                    toast.success('Categoria atualizada! :)')
                    setLoading(false)

                } else {
                    setLoading(false)
                    toast.error(resp.data?.COD+' - '+resp.data?.MSG)
                    return              
                }
            } catch(error) {
                console.log(error)
                setLoading(false)
                toast.error('Erro atualizando categoria!')
                return;
            }            
        }

        setIsUpdate(false)
        setNomeCategoria('')
        setCategoriaUpdate(null)
    }

    async function handleReqDelete(id: string){
        try{
            setLoading(true)
            const apiClient = setupApiClient();

            const response = await apiClient.delete('/categoria',{
                params:{
                    id: id,
                    empresa: user?.id
                }
            })

            if(response.data?.COD == 200){
                const respCategoria = categorias.filter(item => item.ID !== id)
    
                setCategorias(respCategoria)
                setOpenModal(false)
                toast.success('Categoria deletada!')
                setLoading(false)
            } else {
                setLoading(false)
                toast.error(response.data?.COD+' - '+response.data?.MSG)
                return  
            }

        } catch(e) {
            console.log(e)
            toast.error('Categoria não pode ser deletada!')
            setOpenModal(false)
            setLoading(false)
        }
    }

    function handleEdit(item: CategoriaProps){
        setIsUpdate(true)
        setNomeCategoria(item.CATEGORIA)
        setCategoriaUpdate(item)
    }

    function handleCancel(){
        setIsUpdate(false)
        setNomeCategoria('')
        setCategoriaUpdate(null)        
    }

    async function handleDelete(item: CategoriaProps){
        setItemDelete(item)
        setOpenModal(true)
    }

    function PesquisaCategoria(e: ChangeEvent<HTMLInputElement>){
        let filtrar: string, texto: string
        const classcat = document.getElementsByClassName('div-cat-cad')

        filtrar = e.target.value.toUpperCase()
        setPesquisa(filtrar)

        for(let i=0; i < classcat.length; i++){
            texto = classcat[i].innerHTML

            if(texto.toUpperCase().indexOf(filtrar) > -1){
                classcat[i].classList.remove('ocultarLinha')
            } else {
                classcat[i].classList.add('ocultarLinha')  
            }
        }
    }

    if(loading){
        return(
            <main>
                <Header
                    menu={2}
                />
                <Loading/>
            </main>
        )
    }

    return(
        <main onContextMenu={(e) => e.preventDefault()}>
            <Header
                menu={2}
            />  
            <div className="containerCategoria">
                <h2>{ isUpdate ? 'Editando categoria' : 'Cadastro de categoria'}</h2>
            
                <div className='new'>
                    <Input
                        placeholder="Digite a nova categoria..."
                        type='text'
                        value={nomeCategoria}
                        onChange={ (e) => setNomeCategoria(e.target.value)}
                    />
                    <Button onClick={handleSalvar}>
                        <BsCheckLg color='#FFF' style={ {marginRight:'5px'} } />
                        Salvar
                    </Button>

                    { isUpdate && (
                        <div className='btnCancel' onClick={handleCancel}>
                            <button
                                title='CANCELAR'
                            >
                                <MdCancel size={30} color='#ED1C24'/>
                            </button>
                        </div>
                    )}
                </div>

                <div className='div-container-busca'>
                    <h3>Minhas categorias</h3>

                    <div className='div-pesquisa'>
                        <label htmlFor="input-pesqProd">PESQUISAR</label>
                        <input 
                            id='input-pesqProd' 
                            name='input-pesqProd' 
                            type="search" 
                            placeholder='PESQUISAR...'
                            value={pesquisa}
                            onChange={(e) => PesquisaCategoria(e)}
                        />
                    </div>
                </div>
            </div>

            <div className='containerLista'>
                <ul>
                { categorias.length > 0 && categorias.map( (item) => {
                    return(
                        <li key={item?.ID} className='div-cat-cad'>
                            <span style={{ color: `${isUpdate && categoriaUpdate?.ID === item?.ID ? "red" : 'white'}`}}>{item?.CATEGORIA}</span> 
                            <div>
                                <button
                                    onClick={ () => handleEdit(item)}
                                    title='EDITAR'
                                >
                                    <FaEdit size={20} color='#FFF'/>
                                </button>
                                <button
                                    title='EXCLUIR'
                                    onClick={ () => handleDelete(item)}
                                >
                                    <FaTrash size={20} color='#FFF'/>
                                </button>
                            </div>
                        </li>
                        )
                    })}
                </ul>
            </div>

            { openModal && (
                <Modal
                    titulo='Excluir categoria?'
                    titulobtn='Excluir'
                    descricao={itemDelete}
                    onRequest={ (id) => handleReqDelete(id)}
                    onClose={ () => setOpenModal(false)}
                />
            )}

            <Footer/>
        </main>
    
    )
}
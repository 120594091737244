import './index.css'
import banner from '../../../assets/img1.jpg'
import { Link } from 'react-router-dom'
import AnchorLink from 'react-anchor-link-smooth-scroll'


export default function BannerTopo(){
    return(
        <section className='containerTopo'>
            <div className='boxBanner'>
                <div className='txtBanner'>
                    <span>
                        Conheça o sistema MeuBar, 
                        um sistema para gerenciamento de mesas, pedidos e fluxo de caixa para o seu negócio, podendo ser utilizado para restaurantes, bares, pizzarias e diversos estabelecimentos comerciais 100% online.                    
                    </span>
                    
                    <div className='boxBtn'>
                        <Link to='/cadastro/0' target='_blank' id='btnFree'>
                            Experimente grátis por 7 dias
                        </Link>

                        <AnchorLink offset={100} href='#contato' id='btnContato'>
                            Entrar em contato
                        </AnchorLink> 
                    </div>

                </div>

                <div className='boxImg'>
                    <img src={banner} alt='Banner1' width={500} />
                </div>
            </div>
        </section>
    )
    
}
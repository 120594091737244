import { Link } from "react-router-dom"
import './index.css'
import AnchorLink from 'react-anchor-link-smooth-scroll'

export default function ModalMenu(){
    return(
        <nav className='menuMobile'>
            <ul>
                <li>
                    <Link to='/'>
                        Inicio
                    </Link> 
                </li>
                <li>
                    <Link to='/login' target='_blank'>
                        Faça o login
                    </Link> 
                </li>
                <li>
                    <Link to='/cadastro' target='_blank'>
                        Cadastre-se
                    </Link> 
                </li>
                <li>
                    <AnchorLink offset={100} href='#planos'>
                            Planos e preços
                    </AnchorLink> 
                </li>
                <li>
                    <AnchorLink offset={100} href='#contato'>
                            Contato
                    </AnchorLink> 
                </li>
            </ul>
        </nav>
    )
}
import './index.css'
import { HiOutlineMail } from 'react-icons/hi'
import { AiTwotonePhone } from 'react-icons/ai'
import { MdLocationPin } from 'react-icons/md'
import { Link } from 'react-router-dom'

export default function Rodape(){
    const ano = new Date().getFullYear()

    return(
        <footer className='rodape' id='contato'>
            <div className='rodape2'>
                <div className='contentRodape'>
                    <div className='info'>
                        <HiOutlineMail size={20} color='#FFF'/>
                        <span>E-mail: contato@wgainformatica.com.br</span>
                    </div>

                    <div className='info'>
                        <AiTwotonePhone size={20} color='#FFF'/>
                        <span>Telefone: +55 19 3546-5686</span>
                    </div>                
                    
                    <div className='info'>
                        <MdLocationPin size={20} color='#FFF'/>
                        <span>Cidade: Cordeirópolis/SP - Brasil</span>
                    </div>                  
                </div>

                <Link to='/cadastro/0' target='_blank' className='btnFree'>
                    Experimente grátis por 7 dias
                </Link>
            </div>

            <div className='linediv'></div>

            <div className='footerinfo'>
                <span>COPYRIGHT © {ano} WGA Informática - Todos os direitos reservados - Software para comércio em geral - Atendemos todo Brasil</span>
            </div>
        </footer>
    )
}


export function textTransform(value: string): string{
    if(value.length > 0){
        let texto: string = ''
    
        texto = value[0].toUpperCase() + value.toLowerCase().substring(1)
        return texto
    } else {
        return ''
    }
}

export function validaDataModuloTeste(data: string){
    const dataNow = new Date().getTime()
    const userCriado = new Date(data).getTime()
    const msegundos = dataNow - userCriado 
    const dias = msegundos / (1000 * 3600 * 24)

    if(dias > 7){
        return 0 
    } else {
        return dias
    }
}

export function isNumber(value: string){
    const num = /^[0-9]+$/
    return num.test(value)
}

export function adicionarMinutos(data:Date, minutos: number) {
    return new Date(data.getTime() + minutos * 60000);
}
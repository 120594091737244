import { useContext } from 'react'
import './index.css'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import logoImg from '../../assets/logo.svg'
import { BiFoodMenu, BiCategory } from 'react-icons/bi'
import { MdBorderColor } from 'react-icons/md'
import { FiLogOut } from 'react-icons/fi'
import { GiRoundTable, GiMoneyStack } from 'react-icons/gi'
import { toast } from 'react-toastify'
import { AuthContext } from '../../contexts/AuthContext'

interface MenuProps{
    menu: number;
}

export default function Header({ menu }: MenuProps){
    const { user, signOut, } = useContext(AuthContext)
    const navigate = useNavigate()

    function handleSair(){
        try{
            signOut()
            navigate('/')
            toast.info('Até logo... :)')
        } catch{
            
        }
    }

    return(
        <header className='container'>
            <section className='content'>       
                <div className='logo'>
                    <Link to='/dashboard'>
                        <img src={logoImg} alt='Logo MeuBar' width={200}/>    
                    </Link>

                    <span>{user?.name} - <Link to='/account'>Minha conta</Link></span>
                </div>            
            
                <nav className='menu'>
                    <Link 
                    to='/pedidos' 
                    style={ { borderBottom: menu === 1 ? "2px solid #2ECC71" : "0"} }
                    >
                        <MdBorderColor size={18} color='#FFF'/>
                        Pedidos
                    </Link>

                    <Link 
                    to='/categoria' 
                    style={ { borderBottom: menu === 2 ? "2px solid #2ECC71" : "0",} }
                    >
                        <BiCategory size={20} color='#FFF'/>
                        Categoria
                    </Link>      

                    <Link 
                    to='/produtos' 
                    style={ { borderBottom: menu === 3 ? "2px solid #2ECC71" : "0"} }
                    >
                        <BiFoodMenu size={20} color='#FFF'/>
                        Cardápio
                    </Link>   

                    <Link 
                    to='/mesas' 
                    style={ { borderBottom: menu === 4 ? "2px solid #2ECC71" : "0"} }
                    >
                        <GiRoundTable size={20} color='#FFF'/>
                        Mesas
                    </Link>   

                    <Link 
                    to='/caixa' 
                    style={ { borderBottom: menu === 5 ? "2px solid #2ECC71" : "0"} }
                    >
                        <GiMoneyStack size={20} color='#FFF'/>
                        Caixa
                    </Link>   

                    <button className='sair' onClick={handleSair}>
                        <FiLogOut size={20} color='#FFF'/>
                        Sair
                    </button>           
                </nav>           
            </section>
        </header>
    )
}
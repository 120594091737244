import Header from "../../components/Header"
import Footer from "../../components/Footer"
import './index.css'
import { adicionarMinutos } from "../../utils/utils"


export default function Dashboard(){

    function datateste(){
        
        let no = adicionarMinutos(new Date(), 1)
        document.cookie = encodeURIComponent('chave') + "=" + encodeURIComponent('valor1') + "; expires=" + no.toUTCString() + "; path=/";

    }

    return(
        <main onContextMenu={(e) => e.preventDefault()}>
            <Header
                menu={0}
            />

            <div className='containerDashboard'>
                <h3>Dashboard</h3>
            </div>

            <Footer/>
        </main>
    )
}
import './index.css'
import { Link } from 'react-router-dom'

export default function(){
    return(
        <section className='containerPlanos' id='planos'>
            <span>Planos e preços</span>

            <div className='boxPlanos'>

                <div className='planos free'>
                    <div className='itens'>
                        <span>Plano experimental</span>
                        <strong>R$ 0,00</strong>
                    
                        <ul>
                            <li>Cadastros ilimitados</li>
                            <li>Pedidos ilimitados</li>
                            <li>Controle de mesas abertas e ocupadas</li>
                            <li>Abertura e fechamento de caixa</li>
                            <li>Pedidos somente via sistema web</li>
                            <li>Suporte via email e liberação ao sistema</li>
                        </ul>

                        <div className='boxBtn'>
                            <Link to='/cadastro/0' target='_blank' id='btnFree'>
                                Experimente grátis por 7 dias
                            </Link>  
                        </div>
                    </div>
                </div>

                <div className='planos basico'>
                    <div className='itens'>
                        <span>Módulo 1</span>
                        <strong>R$ 99,90<i>/mês</i></strong>

                        <ul>
                            <li>Cadastros ilimitados</li>
                            <li>Pedidos ilimitados</li>
                            <li>Controle de mesas abertas e ocupadas</li>
                            <li>Abertura e fechamento de caixa</li>
                            <li>Pedidos somente via sistema web</li>
                            <li>Cardápio digital por QR Code</li>
                            <li>Cardápio digital personalizado</li>
                            <li>Suporte por email, telefone e WhatsApp</li>
                            <li>Novas atualizações</li>
                        </ul>

                        <div className='boxBtnPremium'>
                            <Link to='/cadastro/1' target='_blank'>
                                Quero este plano
                            </Link>  
                        </div>

                    </div>
                </div>

                <div className='planos interm'>
                    <div className='itens'>
                        <span>Módulo 2</span>
                        <strong>R$ 149,90<i>/mês</i></strong>

                        <ul>
                            <li>Cadastros ilimitados</li>
                            <li>Pedidos ilimitados</li>
                            <li>Controle de mesas abertas e ocupadas</li>
                            <li>Abertura e fechamento de caixa</li>
                            <li>Pedidos via sistema web</li>
                            <li>Pedidos via aplicativo pelo garçom</li>
                            <li>Cardápio digital por QR Code</li>
                            <li>Cardápio digital personalizado</li>
                            <li>Suporte por email, telefone e WhatsApp</li>
                            <li>Novas atualizações</li>
                        </ul>

                        <div className='boxBtnPremium'>
                            <Link to='/cadastro/2' target='_blank'>
                                Quero este plano
                            </Link>  
                        </div>

                    </div>
                </div>

                <div className='planos avancado'>
                    <div className='itens'>
                        <span>Módulo 3</span>
                        <strong>R$ 199,90<i>/mês</i></strong>

                        <ul>
                            <li>Cadastros ilimitados</li>
                            <li>Pedidos ilimitados</li>
                            <li>Controle de mesas abertas e ocupadas</li>
                            <li>Abertura e fechamento de caixa</li>
                            <li>Pedidos via sistema web</li>
                            <li>Pedidos via aplicativo pelo garçom</li>
                            <li>Pedidos via QR Code pelo cliente</li>
                            <li>Cardápio digital por QR Code</li>
                            <li>Cardápio digital personalizado</li>
                            <li>Suporte por email, telefone e WhatsApp</li>
                            <li>Novas atualizações</li>
                        </ul>

                        <div className='boxBtnPremium'>
                            <Link to='/cadastro/3' target='_blank'>
                                Quero este plano
                            </Link>  
                        </div>

                    </div>
                </div>

            </div>

        </section>
    )
}
import './index.css'
import { FaQuestionCircle } from 'react-icons/fa'

export interface ItemProps{
    ID: string
    CATEGORIA: string
}

interface ModalProps{
    titulo: string;
    titulobtn: string;
    descricao: ItemProps;
    onClose: () => void;
    onRequest: (id_produto: string) => Promise<void>;
}

export default function Modal({ titulo, titulobtn, descricao, onClose, onRequest }: ModalProps){
    return(
        <div className='containerModal'>
            <div className='contentModal'>
                
                <div className='headerModal'>
                    <FaQuestionCircle size={50} color='#ED1C24'/>
                    <h3>{titulo}</h3>
                </div>
                
                <main className='contentItem'>

                    <h3>{descricao?.CATEGORIA}</h3>

                    <div className='contentButton'>
                        <button id='ok' onClick={ () => onRequest(descricao?.ID)}>
                            {titulobtn}
                        </button>

                        <button id='cancel' onClick={onClose}>
                            Cancelar
                        </button>

                    </div>

                </main>

            </div>    
        </div>
    )
}
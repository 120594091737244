import './index.css'

interface UrlImg{
    url: string
    onClose: () => void
}

export default function ModalImagem({ url, onClose }: UrlImg){
    return(
        <div className='containerModal'>
            <div className='containerImg'>
                <img src={url} alt="" onClick={onClose}/>
            </div>
        </div>
    )
}
import { useEffect, useState, useContext } from 'react'
import Header from "../../components/Header"
import Footer from '../../components/Footer'
import { useNavigate } from 'react-router-dom'
import './index.css'
import { HiOutlineRefresh } from 'react-icons/hi'
import { MdAddCircleOutline } from 'react-icons/md'
import { GiRoundTable } from 'react-icons/gi'
import { FaEdit, FaTrash } from 'react-icons/fa'
import { setupApiClient } from '../../services/api'
import Loading from '../../components/Loading'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import Modal, { ItemProps } from '../../components/Modal'
import ModalDetalhe, { DetalhePedido } from '../../components/ModalDetalhe'
import { MdPrint } from "react-icons/md"
import { AuthContext } from '../../contexts/AuthContext'
import ModalPrint from '../../components/ModalPrint'

interface PedidoProps{
    ID: string
    MESA: number
    NOME_CLIENTE: string
    TOTALPEDIDO: number
    ENTREGUE: string
    CONCLUIDO?: string
}

export default function Pedidos(){
    const { user } = useContext(AuthContext)
    const navigate = useNavigate()

    const [imprimir, setImprimir] = useState(false)
    const [loading, setLoading] = useState(false)
    const [pedidos, setPedidos] = useState<PedidoProps[]>([])
    const [refresh, setRefresh] = useState(false)

    const [openModal, setOpenModal] = useState(false)
    const [pedidoDelete, setPedidoDelete] = useState<ItemProps>()
    const [obspedido, setObsPedido] = useState('')
    const [openModalDetalhe, setOpenModalDetalhe] = useState(false)
    const [pedidoDetalhe, setPedidoDetalhe] = useState<DetalhePedido>()

    const interval = setInterval( () => {
        setRefresh(!refresh)
        clearInterval(interval)
    }, 1000 * 15)

    useEffect( () => {
        async function getPedidos(){
            try{
                const apiClient = setupApiClient()  

                const response = await apiClient.get('/pedidos',{
                    params:{
                        empresa: sessionStorage.getItem('@id_meu_bar')
                    }
                })

                setPedidos([])
                setPedidos(response.data)
                setLoading(false)

                if(response.data.length > 0){
                    let x = 0
                    response.data.map( (pedido: PedidoProps) => {
                        if(pedido?.CONCLUIDO == 'S'){
                            x += 1
                        }
                    })

                    if(x > 0){
                        const audio = new Audio(require('../../assets/avisopedido.mp3'))
                        audio.play()
                        x = 0
                    }
                }
            } catch {
                toast.error('Erro buscando pedidos!')
                setLoading(false)
            }
        }

        getPedidos()

    },[refresh])

    function handleEditPedido(pedido: PedidoProps){
        navigate(`/pedidos/new/${pedido?.ID}`)
    }

    async function handleImprimir(pedido: PedidoProps){
        try{
            setImprimir(false)

            const apiClient = setupApiClient()
            const response = await apiClient.get('/pedido/detalhe',{
                params:{
                    pedido_id: pedido?.ID,
                    empresa: sessionStorage.getItem('@id_meu_bar')
                }
            })
            
            if(response.data.length > 0){

                let itens: any[] = []
    
                response.data.map( (item: any) => {
                    itens.push({
                        codigoprod: item?.ID, 
                        nomeprod: item?.NOMEPRODUTO,
                        quant: item?.QUANTIDADE,
                        valor: item?.VALOR_UNITARIO,
                        total: item?.TOTAL,
                    })

                    setObsPedido(item?.OBS)
                })
    
                setPedidoDetalhe({
                    ID: pedido?.ID,
                    MESA: pedido?.MESA,
                    NOME: pedido?.NOME_CLIENTE,
                    ITENS : itens
                })

                setImprimir(true)
            } else {
                toast.error('Não encontrei nenhum item no pedido!')
                return                
            }
        } catch(e){
            console.log(e)
            toast.error('Erro abrindo detalhe do pedido!')
        }
    }
    
    function handleDeletePedido(pedido: PedidoProps){
        let mesa = {
            ID: pedido?.ID,
            CATEGORIA: `MESA ${pedido?.MESA} - ${pedido?.NOME_CLIENTE}`
        }
        
        setPedidoDelete(mesa)
        setOpenModal(true)
    }

    async function handleRequestDelete(id: string){
        try{
            setLoading(true)

            const apiClient = setupApiClient();

            const response = await apiClient.delete('/pedido',{
                params:{
                    pedido_id: id,
                    empresa: sessionStorage.getItem('@id_meu_bar')
                }
            })

            if(response.data?.COD == 200){
                const respPedidos = pedidos.filter(pedido => pedido.ID !== id)

                setPedidos(respPedidos)
                setOpenModal(false)
                setLoading(false)
                toast.success('Pedido cancelado!')
            } else {
                setLoading(false)
                toast.error(response.data?.COD+' - '+response.data?.MSG)
                return 
            }

        } catch(e) {
            console.log(e)
            toast.error('Erro cancelando pedido!')
            setLoading(false)            
            setOpenModal(false)
        }
    }

    async function handleDetalhePedido(pedido: PedidoProps){
        try{
            const apiClient = setupApiClient()

            const response = await apiClient.get('/pedido/detalhe',{
                params:{
                    pedido_id: pedido?.ID,
                    empresa: sessionStorage.getItem('@id_meu_bar')
                }
            })

            if(response.data.length > 0){

                let itens: any[] = []
    
                response.data.map( (item: any) => {
                    itens.push({
                        codigoprod: item?.ID, 
                        nomeprod: item?.NOMEPRODUTO,
                        quant: item?.QUANTIDADE,
                        valor: item?.VALOR_UNITARIO,
                        total: item?.TOTAL,
                    })

                    setObsPedido(item?.OBS)
                })
    
                setPedidoDetalhe({
                    ID: pedido?.ID,
                    MESA: pedido?.MESA,
                    NOME: pedido?.NOME_CLIENTE,
                    ITENS : itens
                })
                
                setOpenModalDetalhe(true)
            } else {
                toast.error('Não encontrei nenhum item no pedido!')
                return                
            }
        } catch(e){
            console.log(e)
            toast.error('Erro abrindo detalhe do pedido!')
            setOpenModalDetalhe(false)
        }
    }

    async function handlePreparandoPedido(id: string){
        try{
            const apiClient = setupApiClient()

            const response = await apiClient.put('/pedido/preparacao',{
                pedido_id: id,
                empresa: sessionStorage.getItem('@id_meu_bar')
            })

            if(response.data?.COD == 200){
                setOpenModalDetalhe(false)
                setRefresh(!refresh)
                toast.success('Pedido em preparação!')
            } else {
                setLoading(false)
                toast.error(response.data?.COD+' - '+response.data?.MSG)
                return 
            }
        } catch(e) {
            console.log(e)
            toast.error('Erro preparando o pedido!')
            setOpenModalDetalhe(false)
        }
    }

    async function handleFinalizaPedido(id: string){
        try{
            const apiClient = setupApiClient()

            const response = await apiClient.put('/pedido/entregue',{
                pedido_id: id,
                empresa: sessionStorage.getItem('@id_meu_bar')
            })

            if(response.data?.COD == 200){
                setOpenModalDetalhe(false)
                setRefresh(!refresh)
                toast.success('Pedido finalizado!')
            } else {
                setLoading(false)
                toast.error(response.data?.COD+' - '+response.data?.MSG)
                return 
            }
        } catch(e) {
            console.log(e)
            toast.error('Erro finalizando o pedido!')
            setOpenModalDetalhe(false)
        }
    }

    if(loading){
        return(
            <main>
                <Header
                    menu={1}
                />
                <Loading/>
                <Footer/>
            </main>
        )
    }

    return(
        <main onContextMenu={(e) => e.preventDefault()}>
            <Header
                menu={1}
            />

            <div className='containerPedidos'>
                <div className='contentPedidoHeader'>
                    <h2>Meus pedidos</h2> 

                    <Link to='/pedidos/new' id='novo'>
                        <MdAddCircleOutline size={20} color='#FFF'/>
                        Novo Pedido
                    </Link>

                    <button 
                        id='refresh' 
                        onClick={ () => setRefresh(!refresh)} 
                    >
                        <HiOutlineRefresh size={30} color='#aacc2e'/>                    
                    </button> 
                </div>

                <article className='contentPedidos'>
                    { pedidos.length > 0 && pedidos.map( (pedido) => {
                        return(
                            <section className='contentmesa' key={pedido?.ID}>
                                <div className='mesa'>
                                    <div className='tag' style={{backgroundColor: pedido?.CONCLUIDO == 'S' ? '#2ECC71' : '#ED1C24'}}></div> 
                                    <button className='btnMesa' onClick={ () => handleDetalhePedido(pedido)}>
                                        <span>
                                            <GiRoundTable size={20} color='#FFF'/>
                                            MESA {pedido?.MESA} {pedido?.NOME_CLIENTE ? `- ${pedido?.NOME_CLIENTE}` : ''} 
                                            <span className='txtpreparacao'>{pedido?.CONCLUIDO == 'X' ? 'EM PREPARAÇÃO' : ''}</span>    
                                        </span>
                                    </button>
                                </div>

                                <div className='edtMesa'>
                                    <button title='EDITAR PEDIDO' onClick={ () => handleEditPedido(pedido)}>
                                        <FaEdit size={20} color='#FFF'/>
                                    </button>

                                    <button title='CANCELAR PEDIDO' onClick={ () => handleDeletePedido(pedido)}>
                                        <FaTrash size={20} color='#ED1C24'/>
                                    </button>
                                    <button title='IMPRIMIR PEDIDO' onClick={() => handleImprimir(pedido)}>
                                        <MdPrint size={24} color='#FFF'/>
                                    </button>
                                </div>
                            </section>
                        )
                    })}
                </article>

                { openModal && (
                    <Modal
                        titulo='Cancelar o Pedido?'
                        titulobtn='Cancelar o pedido'
                        descricao={pedidoDelete}
                        onClose={ () => setOpenModal(false)}
                        onRequest={ (id) => handleRequestDelete(id)}
                    />
                )}

                {openModalDetalhe && (
                    <ModalDetalhe
                        data={pedidoDetalhe}
                        obspedido={obspedido}
                        onClose={ () => setOpenModalDetalhe(false)}
                        onRequest={ (id) => handleFinalizaPedido(id)}
                        onRequestPreparacao={ (id) => handlePreparandoPedido(id)}
                    />
                )}

                { imprimir && (
                    <ModalPrint
                        data={pedidoDetalhe}
                        obspedido={obspedido}
                        imprimir={imprimir}
                        onClose={() => setImprimir(false)}
                    />
                )}

                <Footer/>
            </div>
        </main>
    )
}
import './index.css'
import logo from '../../assets/logo.svg'

export default function NotFound(){
    return(
        <div className='containerNotfound' onContextMenu={(e) => e.preventDefault()}>
            <img src={logo} alt='MeuBar' width={150} height={120} />

            <div className='notfound'>
                <h1>404</h1>
                <span>|</span>
                <h2>Not Found</h2>
            </div>
        </div>
    )
}
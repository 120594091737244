import './index.css'
import { MdBorderColor } from 'react-icons/md'
import { BiFoodMenu, BiCategory } from 'react-icons/bi'
import { GiRoundTable, GiMoneyStack } from 'react-icons/gi'

export default function Funcionalidades(){
    return(
        <section className='containerFunc'>
            
            <span>Funcionalidades</span>

            <div className='boxContainer'>
                <div className='funcoes'>
                    <div className='boxFuncao'>
                        <div className='boxicon'>
                            <MdBorderColor size={80} color='#000'/>  
                        </div>
                        <i>Pedidos</i>
                    </div>
                </div>

                <div className='funcoes'>
                    <div className='boxFuncao'>
                        <div className='boxicon'>
                            <BiCategory size={80} color='#000'/>  
                        </div>
                        <i>Categorias</i>
                    </div>
                </div>

                <div className='funcoes'>
                    <div className='boxFuncao'>
                        <div className='boxicon'>
                            <BiFoodMenu size={80} color='#000'/>  
                        </div>
                        <i>Cardápio</i>
                    </div>
                </div>             
            </div>
            
            <div className='boxContainer'>
                <div className='funcoes'>
                    <div className='boxFuncao'>
                        <div className='boxicon'>
                            <GiRoundTable size={80} color='#000'/>  
                        </div>
                        <i>Mesas</i>
                    </div>
                </div>

                <div className='funcoes'>
                    <div className='boxFuncao'>
                        <div className='boxicon'>
                            <GiMoneyStack size={80} color='#000'/>  
                        </div>
                        <i>Fluxo de caixa</i>
                    </div>
                </div>
            </div>           
        </section>
    )
}
import { useState, FormEvent } from 'react'
import './index.css'
import logo from '../../assets/logo_login.svg'
import { Input } from '../../components/Input'
import { Button } from '../../components/Button'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { setupApiClient } from '../../services/api'
import ReCAPTCHA from 'react-google-recaptcha'
import { MdHome, MdEmail, MdRefresh } from "react-icons/md"
import { HiLockClosed, HiLockOpen  } from "react-icons/hi"
import { IoCheckmarkDoneSharp } from "react-icons/io5"
import { useParams } from 'react-router-dom'

export default function Cadastro(){
    const navigate = useNavigate()
    const { id } = useParams()

    const [nome, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassowrd] = useState('')
    const [versenha, setVerSenha] = useState(false)
    const [loading, setLoading] = useState(false)
    const [reCaptcha, setReCaptcha] = useState(null)
    
    async function handleCadastrar(e: FormEvent){
        e.preventDefault()

        if(nome === '' || email === '' || password === ''){
            toast.warn('Preencha todos os campos!')
            return;
        }

        if (!reCaptcha){
            toast.warn('Marque o captcha!')
            return;           
        }
      
        try{
            setLoading(true)

            const apiClient = setupApiClient()

            const response = await apiClient.post('/cadastro',{
                nome: nome.toUpperCase(),
                email: email.toLowerCase(),
                senha: password,
                modulo: 0,
                validacao: Math.floor(Math.random() * (9000 - 1000) + 1000)
            })

            if(response.data?.COD == 201){
                setNome('')
                setEmail('')
                setPassowrd('')
                setLoading(false)
                navigate('/login')
                toast.success('Conta criada, verifique seu email! :)')
            } else {
                setLoading(false)
                toast.error(response.data?.COD+' - '+response.data?.MSG)
                return 
            }

        } catch (error) {
            setLoading(false)
            toast.error('Erro criando a conta! :(')
            console.log(error)
        }
    }

    function onChange(value: string){
        setReCaptcha(value);
    }
    
    return(
        <main className="containerL" onContextMenu={(e) => e.preventDefault()}>

            <div className='containerLogin cad'>
                <img src={logo} alt='Logo MeuBar' width={250} height={100} />
                <h3>Cadastro de acesso</h3>

                <form onSubmit={handleCadastrar}>
                    <div className='div-linha'>
                        <MdHome size={25} color='#fff' />
                        <Input
                            placeholder='Digite seu nome ou estabelecimento...'
                            type='text'
                            value={nome}
                            onChange={ (e) => setNome(e.target.value)}
                        />
                    </div>
                    
                    <div className='div-linha'>
                        <MdEmail size={25} color='#fff' />
                        <Input
                            placeholder='Digite seu email...'
                            type='email'
                            value={email}
                            onChange={ (e) => setEmail(e.target.value)}
                        />
                    </div>

                    <div className='div-linha'>
                        { versenha ? (
                            <HiLockOpen style={{cursor:'pointer'}} onClick={() => setVerSenha(!versenha)} size={25} color='#fff' />
                        ) : (
                            <HiLockClosed style={{cursor:'pointer'}} onClick={() => setVerSenha(!versenha)} size={25} color='#fff' />
                        )}
                        <Input
                            placeholder='Digite sua senha...'
                            type={ versenha ? 'text' : 'password'}
                            value={password}
                            onChange={ (e) => setPassowrd(e.target.value)}
                        />
                    </div>

                    <div className='recaptcha'>
                        <ReCAPTCHA
                            theme='dark'
                            sitekey="6LeWSvkkAAAAAIgGHdlBs_0xCwM26AsNQJCiA42G"
                            onChange={onChange}
                        />
                    </div>

                    <Button type='submit'>
                        { loading ? (
                            <MdRefresh className='svg-enviar' size={25} color='#fff'/>
                        ):(
                            <>
                            <IoCheckmarkDoneSharp style={{marginRight:5}} size={20} color='#fff'/>
                            Cadastrar
                            </>
                        )}
                    </Button>
                </form>
            </div>

        </main>
    )
}
import logoImg from '../../../assets/logo.svg'
import './index.css'
import { Link } from 'react-router-dom'
import { HiOutlineMenu } from 'react-icons/hi'
import { AiOutlineClose } from "react-icons/ai"
import AnchorLink from 'react-anchor-link-smooth-scroll'

interface MenuProps{
    onOpenMenu: () => void;
    onMenu: boolean
}

export default function HeaderHome({ onMenu, onOpenMenu }: MenuProps){
    return(
        <nav className='menuNav'>
            <div className='boxCenter'>
                <button className='btnMenu' onClick={onOpenMenu}>
                    { onMenu ? (
                        <AiOutlineClose size={30} color='#fff'/>
                    ):(
                        <HiOutlineMenu size={30} color='#FFF' />
                    )}
                </button>

                <div className='boxMenu'>
                    <ul>
                        <li>
                            <Link to='/'>
                                Inicio
                            </Link> 
                        </li>
                        <li>
                            <Link to='/login' target='_blank'>
                                Faça o login
                            </Link> 
                        </li>
                        <li>
                            <Link to='/cadastro/0' target='_blank'>
                                Cadastre-se
                            </Link> 
                        </li>
                        <li>
                            <AnchorLink offset={100} href='#planos'>
                                Planos e preços
                            </AnchorLink> 
                        </li>
                        <li>
                            <AnchorLink offset={100} href='#contato'>
                                Contato
                            </AnchorLink> 
                        </li>
                    </ul>
                </div>
                
                <div className='logoMenu'>
                    <Link to='/'>
                        <img src={logoImg} alt='Logo' width='200' />
                    </Link>
                </div>
            </div>
        </nav>
    )
}
import { useState, FormEvent, useContext, useEffect } from 'react'
import './index.css'
import logo from '../../assets/logo_login.svg'
import { Input } from '../../components/Input'
import { Button } from '../../components/Button'
import { toast } from 'react-toastify'
import { AuthContext } from '../../contexts/AuthContext'
import { MdEmail, MdRefresh } from "react-icons/md"
import { HiLockClosed, HiLockOpen  } from "react-icons/hi"
import { IoCheckmarkDoneSharp, IoKey } from "react-icons/io5"
import { setupApiClient } from '../../services/api'


export default function Login(){
    const { signIn, validarEmail, validaremail } = useContext(AuthContext)

    const [email, setEmail] = useState('')
    const [password, setPassowrd] = useState('')
    const [loading, setLoading] = useState(false)
    const [codigoValidacao, setCodigoValidacao] = useState('')
    const [versenha, setVerSenha] = useState(false)

    useEffect( () => {
        const emailAcesso = localStorage.getItem('@emailAcesso')

        if(emailAcesso){
            setEmail(emailAcesso)
        }
    },[])

    async function handleValidarCodigo(){
        if(codigoValidacao == ''){
            toast.warn('Código de validação inválido!')
            return
        }

        try{
            const apiClient = setupApiClient();

            let obj = {
                email: email,
                codigovalidacao: codigoValidacao
            }

            const response = await apiClient.post('/validaremail', obj)

            if(response.data?.COD == 200){
                toast.success('Email validado, faça o login! :)')
                validaremail(false)
                setCodigoValidacao('')
            } else {
                validaremail(true) 
                toast.error(response.data?.COD+' - '+response.data?.MSG)
                return 
            }
        } catch(e){
            console.log(e)
            validaremail(true)
            return
        }
    }

    async function handleLogin(e: FormEvent){
        e.preventDefault()

        if(email === '' || password === ''){
            toast.warn('Preencha suas credenciais!')
            return;
        }

        try{
            setLoading(true)

            await signIn({
                email: email,
                senha: password
            })

            setLoading(false)

        } catch(e) {
            console.log(e)
            setLoading(false)
        }
    }
    
    return(
        <main className="containerL">

            <div className='containerLogin'>
                <img src={logo} alt='Logo MeuBar' width={250} height={100} />
                <h3>Digite suas credenciais de acesso</h3>

                <form onSubmit={handleLogin}>
                    <div className='div-linha'>
                        <MdEmail size={25} color='#fff' />
                        <Input
                            placeholder='Digite seu email...'
                            type='email'
                            value={email}
                            onChange={ (e) => setEmail(e.target.value)}
                        />

                    </div>

                    <div className='div-linha'>
                        { versenha ? (
                            <HiLockOpen style={{cursor:'pointer'}} onClick={() => setVerSenha(!versenha)} size={25} color='#fff' />
                        ) : (
                            <HiLockClosed style={{cursor:'pointer'}} onClick={() => setVerSenha(!versenha)} size={25} color='#fff' />
                        )}
                        <Input
                            placeholder='Digite sua senha...'
                            type={versenha ? 'text' : 'password'}
                            value={password}
                            onChange={ (e) => setPassowrd(e.target.value)}
                        />
                    </div>

                    <Button type='submit' style={{marginTop:10}}>
                        { loading ? (
                            <MdRefresh className='svg-enviar' size={25} color='#fff'/>
                        ):(
                            <>
                            <IoCheckmarkDoneSharp style={{marginRight:5}} size={20} color='#fff'/>
                            Acessar
                            </>
                        )}
                    </Button>
                </form>

                { validarEmail && (
                    <div className='div-linha-validacao'>
                        <IoKey size={25} color='#fff'/>
                        <Input
                            placeholder='Digite código de validação recebido...'
                            type='text'
                            value={codigoValidacao}
                            onChange={ (e) => setCodigoValidacao(e.target.value)}
                        />
                        <button className='btn-validaremail' onClick={handleValidarCodigo}>
                            Validar
                        </button>                                
                    </div>
                )}
            </div>
        </main>
    )
}
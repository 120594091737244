import './index.css'
import { IoMdRefresh } from 'react-icons/io'

export default function Loading(){
    return(
        <div className='loading'>
            <IoMdRefresh size={60} color='#FFF'/>
            <h2>Carregando...</h2>
        </div>  
    )
}
import { createContext, ReactNode, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { setupApiClient } from '../services/api'
import { setCookie, destroyCookie, parseCookies } from 'nookies'
import { useNavigate } from 'react-router-dom'
import { validaDataModuloTeste } from '../utils/utils'

type AuthContextProps = {
    user: UserProps;
    isAuthenticated: boolean;
    signIn: (credentials: LoginProps) => Promise<void>;
    signOut: () => void;
    updateUser: (data: UserProps) => void;
    validarEmail: boolean
    validaremail: (value: boolean) => void
    premium: boolean
    validarPremium: (value: boolean) => void
    diasUso: number
    updateDiasUso: (dias: number) => void
    inputNome: boolean
    desabilitaInputNome: (value: boolean) => void
}

type UserProps = {
    id: string;
    name: string;
    email: string;
    bloqueado?: string
    pago?: string 
    modulo?: number
}

type LoginProps = {
    email: string
    senha: string
}

type AuthProviderProps = {
    children: ReactNode;
}

export const AuthContext = createContext({} as AuthContextProps);

export async function signOut(){
    try{
        destroyCookie(null,'@MeuBarToken');
        localStorage.removeItem('@MeuBarLocal')
    } catch(error) {
        console.log(error)
        toast.error('Erro ao deslogar!')
    }
}

export function AuthProvider({ children }: AuthProviderProps){
    const navigate = useNavigate()

    const [validarEmail, setValidarEmail] = useState(false)
    const [user, setUser] = useState<UserProps>()
    const isAuthenticated = !!user
    const [premium, setPremium] = useState(false)
    const [diasUso, setDiasUso] = useState(7)
    const [inputNome, setInputNome] = useState(true)

    useEffect( () => {
        async function getUser(){
            const cookie = parseCookies()
            const token = cookie['@MeuBarToken']
            
            if(token){
                const apiClient = setupApiClient();

                await apiClient.get('/me',{
                    params:{
                        email: localStorage.getItem('@emailAcesso')
                    }
                })
                .then( (response) => {
                    const { ID, NOME, EMAIL, CRIADO, BLOQUEADO, PAGO, MODULO } = response.data;
                    
                    let data = {
                        id: ID,
                        name: NOME,
                        email: EMAIL,
                        bloqueado: BLOQUEADO,
                        pago: PAGO,
                        modulo: MODULO
                    }

                    sessionStorage.setItem('@id_meu_bar',ID)
                    setUser(data)

                    if(String(MODULO) == process.env.REACT_APP_MODULO && validaDataModuloTeste(CRIADO) <= 0){
                        validarPremium(false)
                        updateDiasUso(validaDataModuloTeste(CRIADO))
                    } else {
                        validarPremium(true)
                        updateDiasUso(validaDataModuloTeste(CRIADO))
                    }
                })
                .catch( () => {
                    signOut()
                    navigate('/')
                })
            }
        }

        getUser();       

    },[])

    function updateUser(data: UserProps){
        setUser(data)
    }

    function validaremail(value: boolean){
        setValidarEmail(value)
    }

    function desabilitaInputNome(value: boolean){
        setInputNome(value)
    }

    function updateDiasUso(dias: number){
        setDiasUso(dias)
    }

    function validarPremium(value: boolean){
        setPremium(value)
    }

    async function signIn({ email, senha }: LoginProps){
        try{
            const apiClient = setupApiClient();

            const response = await apiClient.post('/login', {
                email: email,
                senha: senha,
            })

            if(response.data?.COD == 105){
                toast.warn(`Email/senha não encontrado! :(`)
                return                  
            }

            if(response.data?.COD == 401){
                toast.warn(`Email/senha inválidos! :(`)
                return                  
            }

            const { CODIGO, NOME, EMAIL, CRIADO, MODULO, BLOQUEADO, PAGO, VALIDADO, LOCAL, TOKEN } = response.data;

            if(VALIDADO == process.env.REACT_APP_VALIDADO){
                setValidarEmail(true)
                toast.warn(`Email não verificado, valide seu email! :(`)
                return    
            }
            
            if(BLOQUEADO == process.env.REACT_APP_BLOQUEADO){
                toast.warn(`Estabelecimento bloqueado, sua assinatura pode ter sido cancelada! :(`)
                validarPremium(false)
            }

            if(String(MODULO) == process.env.REACT_APP_MODULO && validaDataModuloTeste(CRIADO) <= 0){
                toast.warn(`Seu período de teste acabou! :(`)
                validarPremium(false)
                updateDiasUso(validaDataModuloTeste(CRIADO))
            } else {
                validarPremium(true)
                updateDiasUso(validaDataModuloTeste(CRIADO))
            }

            setCookie(null,'@MeuBarToken', TOKEN, {
                maxAge: 60 * 60 * 24 * 30,
                path: "/"
            })

            localStorage.setItem('@MeuBarLocal', LOCAL)
            localStorage.setItem('@emailAcesso', email)
            sessionStorage.setItem('@id_meu_bar',CODIGO)

            setUser({
                id: CODIGO,
                name: NOME,
                email: EMAIL,
                bloqueado: BLOQUEADO,
                pago: PAGO,
                modulo: MODULO
            })

            toast.success(`Bem vindo de volta ${NOME} :)`)
            navigate('/dashboard')
        } catch(error) {
            console.log(error)
            toast.error('Erro email/senha inválidos!')
        }
    }

    return(
        <AuthContext.Provider value={ { user, isAuthenticated, signIn, 
                                        signOut, updateUser, validarEmail, 
                                        validaremail, premium, validarPremium, 
                                        diasUso, updateDiasUso,
                                        inputNome, desabilitaInputNome } }>
            {children}
        </AuthContext.Provider>
    )
}




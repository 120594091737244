import { ButtonHTMLAttributes } from 'react'
import './index.css'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>{}

export function Button({...rest}: ButtonProps){
    return(
        <button
            className='button'
            {...rest}
        >

        </button>
    )
}